<template>
    <div class="">
        <h4 class="font-active mb-2 text-center light-black" v-if="page==='valentines-landing'">
            Valentine’s Magic : <br><span class="magic">Gifts to Treasure</span>
        </h4>
        <h4 class="mb-4 text-center font-active" v-else>Shop Gifts By Price</h4>

        <p class="mb-3 text-center light-grey" v-if="page==='valentines-landing'">Shop diamond jewelry that’s thoughtful <br>and within reach.</p>
           
        <transition name="fade">
            <swiper v-bind="swiperOptions" @swiper="onSwiperLoad">
                <swiper-slide>
                  <NuxtLink :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-1000' } })">
                    <div class="img_box radious_eight">
                      <picture>
                        <source width="1500" height="1388" type="image/webp" srcset="~/assets/home/price/black-friday/price1.webp">
                        <img loading="lazy" fluid width="1500" height="1388" type="image/jpeg" src="~/assets/home/price/black-friday/price1.jpg" alt="Engagement Rings">
                      </picture>
                    </div>
                    <span class="gift-text">Gifts Under $1000</span>
                  </NuxtLink>
                </swiper-slide>
                <swiper-slide>
                  <NuxtLink :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-2000' } })">
                    <div class="img_box radious_eight">
                      <picture>
                        <source width="1500" height="1388" type="image/webp" srcset="~/assets/home/price//black-friday/price2.webp">
                        <img loading="lazy" type="image/jpeg" fluid width="1500" height="1388" src="~/assets/home/price/black-friday//price2.jpg" alt="Eternity Rings">
                      </picture>
                    </div>
                    <span class="gift-text">Gifts Under $2000</span>
                  </NuxtLink>
                </swiper-slide>
                <swiper-slide>
                  <NuxtLink :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-3000' } })">
                    <div class="img_box radious_eight">
                      <picture>
                        <source width="1500" height="1388" type="image/webp" srcset="~/assets/home/price/black-friday//price3.webp">
                        <img loading="lazy" type="image/jpeg" fluid width="1500" height="1388" src="~/assets/home/price/black-friday/price3.jpg" alt="Diamond Pendants">
                      </picture>
                    </div>
                    <span class="gift-text">Gifts Under $3000</span>
                  </NuxtLink>
                </swiper-slide>
            </swiper>
        </transition>

        <div v-show="skeleton">
            <div class="row for_desktop">
                <div class="col-md-4" v-for="index in 3" :key="index">
                    <div class="prod_block">
                        <div class="prod_image skeleton_lg">
                            <!-- <b-skeleton-img class="mx-auto" width="100%" height="400px"></b-skeleton-img> -->
                        </div>
                        <div class="prod_image skeleton_md">
                            <!-- <b-skeleton-img class="mx-auto" width="100%" height="180px"></b-skeleton-img> -->
                        </div>
                        <!-- <b-skeleton class=" my-2" width="100%" height="40px"></b-skeleton> -->
                    </div>
                </div>
            </div>
            <div class="for_mobile">
                <div class="prod_block">
                    <div class="prod_image">
                        <!-- <b-skeleton-img class="mx-auto" width="100%" height="260px"></b-skeleton-img> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script setup>
const props = defineProps({
  locale: String,
  page: String
})
const localePath = useLocalePath()
const skeleton = ref(true);

const swiperOptions = {
    slidesPerGroup:1,
    spaceBetween:10,
    
    breakpoints: {
        0: {
            slidesPerView: 1.2,
            centeredSlides:true,
            centeredSlidesBounds:true,
        },

        540: {
            slidesPerView: 1.5,
            centeredSlides:true,
            centeredSlidesBounds:true,
        },

        680: {
            slidesPerView: 1.7,
            centeredSlides:true,
            centeredSlidesBounds:true,
        },
      
        768: {
            slidesPerView: 3,
        }
    },
  };

  const onSwiperLoad = (swiper)=>{
      swiper?.slideTo(1)
  }

onMounted(() => {
  nextTick(() => {
    skeleton.value = false;
  });
});
</script>


<style scoped>

.transform-enter-active,
.transform-leave-active {
    transition: transform 0.5s linear;
    transform: translateY(-20px);
}

.transform-enter,
.transform-leave-to {
    transform: translateY(0px);

}

.slide_box{
    text-align: center;
    padding: 2%;
}


h4{
    font-size: 30px;
}

.light-black{
    color: #353535;
}
.light-black br{
 display: none;
}

.light-grey{
    color: #606060;
}

.light-grey br{
  display: none;
}

h4 .magic{
    color: #a41e36;
    display: inline;
    font-style: italic;
}
p{
    font-weight: 500;
    color: #000;
}
a{
    display: inline-block;
}
.gift-text{
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    display: block;
    text-align: center;
    color: #000;
    padding-top: 5%;
}

@media(max-width: 767px){
    h4{
        font-size: 24px;
    }
    p{
        font-size: 16px;
        padding-left: 5%;
        padding-right: 5%;
    }
    .gift-text{
        font-size: 14px;
        /* letter-spacing: 1.5; */
    }
    .light-black br {
        display: block;
    }
    .light-grey{
        font-size: 14px;
    }
    .light-grey br {
        display: block;
    }
}


/* --------animation---------- */
.img_box{
    overflow: hidden;
    max-width: 462px;
    max-height: 438px;
}
img{
    transition: 0.8s; 
    /* image-rendering: pixelated; */
}
.img_box img:hover{
    transform: scale(1.2);
    transition: 0.8s;  
}
.slide_box:hover .gift-text{
    color: var(--green);
}
/* --------animation---------- */
</style>